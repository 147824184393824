(function($) {
    "use strict";

    var loginCaptcha,
        regCaptcha,
        adRegCaptcha;
    window.cthCaptchaCallback = function() {
        if(document.getElementById("loginCaptcha")) loginCaptcha = grecaptcha.render('loginCaptcha', {
            'sitekey' : _grafon_add_ons.gcaptcha_key,
        });
        if(document.getElementById("regCaptcha")) regCaptcha = grecaptcha.render('regCaptcha', {
            'sitekey' : _grafon_add_ons.gcaptcha_key,
        });
        if(document.getElementById("adRegCaptcha")) adRegCaptcha = grecaptcha.render('adRegCaptcha', {
            'sitekey' : _grafon_add_ons.gcaptcha_key,
        });
        
    };


    $("form#rsUserLogin").on('submit', function(event){
        event.preventDefault()
        $('#logmessage').removeClass('show success')
        var data = $(this).serialize()
        $('#logsubmit').attr('disabled', true).addClass('submitted')
        $.ajax({
            method: "POST",
            url: _grafon_add_ons.url,
            data: data + '&action=grafon-login'
        })
        .done(function( res ) {
            // console.log(res)
            $('#logsubmit').removeClass('submitted')
            if(res.success == false){
                
                if( res.error != null) $('#logmessage').html(res.error).addClass('show')
                $('#logsubmit').attr('disabled', false).removeClass('submitted')
                if(_grafon_add_ons.gcaptcha) grecaptcha.reset(loginCaptcha);
            }else{
                if( res.message != null) $('#logmessage').html(res.message).addClass('show success')
                setTimeout(()=>{
                    if(res.redirection != null) 
                        window.location.href = res.redirection;
                    else
                        if(_grafon_add_ons.gcaptcha) grecaptcha.reset(loginCaptcha);
                    // modal.hide();
                }, 5000);
            }
        })
        // emd done 
        .fail(function(jqXHR, textStatus, errorThrown ){
            console.log(jqXHR)
        });
        // end fail
        
        return false;
    });
    
    // for user registration form
    $("form#rsUserRegistration").on('submit', function(event){
        event.preventDefault()
        $('#regmessage').removeClass('show success')
        var data = $(this).serialize()
        $('#regsubmit').attr('disabled', true).addClass('submitted')
        $.ajax({
            method: "POST",
            url: _grafon_add_ons.url,
            data: data + '&action=grafon-register'
        })
        .done(function( res ) {
            // console.log(res)
            $('#regsubmit').removeClass('submitted')
            if(res.success == false){
                
                if( res.error != null) $('#regmessage').html(res.error).addClass('show')
                $('#regsubmit').attr('disabled', false).removeClass('submitted')
                if(_grafon_add_ons.gcaptcha) grecaptcha.reset(regCaptcha);
            }else{
                if( res.message != null) $('#regmessage').html(res.message).addClass('show success')
                setTimeout(()=>{
                    if(res.redirection != null) 
                        window.location.href = res.redirection;
                    else
                        if(_grafon_add_ons.gcaptcha) grecaptcha.reset(regCaptcha);
                    // modal.hide();
                }, 5000);
            }
        })
        // emd done 
        .fail(function(jqXHR, textStatus, errorThrown ){
            console.log(jqXHR)
        });
        // end fail
        
        return false;
    });

    $("form#advancedUserRegistration").on('submit', function(event){
        event.preventDefault()
        $('#advregmessage').removeClass('show success')
        var data = $(this).serialize()
        $('#advregsubmit').attr('disabled', true).addClass('submitted')
        $.ajax({
            method: "POST",
            url: _grafon_add_ons.url,
            data: data + '&action=grafon-register'
        })
        .done(function( res ) {
            // console.log(res)
            $('#advregsubmit').removeClass('submitted')
            if(res.success == false){
                
                if( res.error != null) $('#advregmessage').html(res.error).addClass('show')
                $('#advregsubmit').attr('disabled', false).removeClass('submitted')
                if(_grafon_add_ons.gcaptcha) grecaptcha.reset(adRegCaptcha);
            }else{
                if( res.message != null) $('#advregmessage').html(res.message).addClass('show success')
                setTimeout(()=>{
                    if(res.redirection != null) 
                        window.location.href = res.redirection;
                    else
                        if(_grafon_add_ons.gcaptcha) grecaptcha.reset(adRegCaptcha);
                    // modal.hide();
                }, 5000);
            }
        })
        // emd done 
        .fail(function(jqXHR, textStatus, errorThrown ){
            console.log(jqXHR)
        });
        // end fail
        
        return false;
    });

})(jQuery);

